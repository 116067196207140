import React from 'react'
import ImageGallery from '../components/ImageGallery';
import ThemeItem from '../components/ThemeItem'
import ThemeSlider from '../components/ThemeSlider'

// Images
import demo1 from '../img/themes/demo(1).webp'
import demo2 from '../img/themes/demo(2).webp'
import demo3 from '../img/themes/demo(3).webp'
import demo4 from '../img/themes/demo(4).webp'
import demo1Thumb from '../img/themes/demo(1).avif'
import demo2Thumb from '../img/themes/demo(2).avif'
import demo3Thumb from '../img/themes/demo(3).avif'
import demo4Thumb from '../img/themes/demo(4).avif'


const images = [
  { src: demo1, thumb: demo1, alt: 'Image 1' },
  { src: demo2, thumb: demo2, alt: 'Image 2' },
  { src: demo3, thumb: demo3, alt: 'Image 3' },
  { src: demo4, thumb: demo4, alt: 'Image 4' },
];

export default function detail() {
  return (
    <>
    <section className="detail-main-section py-5">
      <div className="container-lg cl-custome cl-1260-width">
        <div className="dms-inner">
          <div className="dms-i-left">
            <div className="dms-name">25HourNews</div>
            <div className="dms-price">$280 USD</div>
            <div className="dms-about">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</div>
            <div className="dms-separator"></div>
            <div className="dms-tag-bx">
              <div className="dtb-top">Reviews (234)</div>
              <div className="dtb-other">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
                </svg>
                99% positive
              </div>
            </div>
            <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
          </div>
          <div className="dms-i-right">
            <ThemeItem
              className="tib-hover-off dms-theme-item"
              mainImage={demo1} 
              thumbImage={demo1Thumb} 
            />
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container-lg cl-custome">
          <div className="common-heading-bx pb-2 pb-md-2">
            <div className="common-heading">View Gallery</div>
            {/* <div className="common-sub-heading pb-md-0 pb-2">Made with core features you can easily customize—no coding needed. Get started fast with templates for your industry.</div> */}
          </div>
          <ImageGallery images={images} />
      </div>
    </section>

    <section className="py-5">
      <div className="container-lg cl-custome">
        <div className="common-heading-bx pb-4 pb-md-0">
          <div className="common-heading">More Themes</div>
          <div className="common-sub-heading pb-md-0 pb-2">Made with core features you can easily customize—no coding needed. Get started fast with templates for your industry.</div>
        </div>
        <ThemeSlider>
          <div className="theme-slider-item">
            <ThemeItem 
              name="King" 
              price="$400" 
              mainImage={demo1} 
              thumbImage={demo1Thumb} 
              review="75% (4)" 
            />
          </div>
          <div className="theme-slider-item">
            <ThemeItem 
              name="Madrid" 
              price="$400" 
              mainImage={demo2} 
              thumbImage={demo2Thumb} 
              review="75% (4)" 
            />
          </div>
          <div className="theme-slider-item">
            <ThemeItem 
              name="Motion" 
              price="$400" 
              mainImage={demo3} 
              thumbImage={demo3Thumb} 
              review="75% (4)" 
            />
          </div>
          <div className="theme-slider-item">
            <ThemeItem 
              name="Vision" 
              price="$400" 
              mainImage={demo4} 
              thumbImage={demo4Thumb} 
              review="75% (4)" 
            />
          </div>
        </ThemeSlider>
      </div>
    </section>
    </>
  )
}
