
import React from 'react';
import { Link, NavLink } from "react-router-dom";

// Design Files
import { useMenu } from '../../context/MenuContext';
import Logo from '../../components/Logo';
import Socialicon from '../../components/Socialicon';
import Icons from '../../components/icons';

export default function Sidemenu() {

    const { isActive, toggleMenu } = useMenu();

    return (
        <>
            <aside className={`side-menu-bx ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
                <div className="smb-logo-bx">
                    <span style={{top:"17px"}} className="close-icon trigger-closed" onClick={toggleMenu}>
                        <Icons.XMarkSvg />
                    </span>

                    <Logo className="smb-logo-link" />
                </div>

                <div className="smb-menu-bx">

                    <div className="smb-signinup-btn mt-2">
                        <Link to="/signin" className="common-btn-item cbi-outline">Sign In</Link>
                        <Link to="/signup" className="common-btn-item cbi-fill">Sign Up</Link>
                    </div>
                
                    <ul className='pb-0'>
                        <div className="smb-nav-lable mt-3 mb-2">Explore</div>
                        <li><NavLink to="/" className="smb-link">Home</NavLink></li>
                        <li><NavLink to="/about" className="smb-link">About Us</NavLink></li>
                        <li><Link className="smb-link">Blog</Link></li>
                        <li><NavLink to="/privacy-policy" className="smb-link">Privacy Policy</NavLink></li>
                        <li><NavLink to="/terms-and-conditions" className="smb-link">Terms & Conditions</NavLink></li>
                        <li><Link className="smb-link">Contact Us</Link></li>
                        <li><NavLink to="/sitemap" className="smb-link">Sitemap</NavLink></li>
                    </ul>

                    <div className="py-5"></div>

                </div>

                <div className="smb-footer">
                    <Socialicon className={`social-icon justify-content-center `} />
                </div>

            </aside>

            <div className={`overlaydiv ${isActive ? 'active' : ''}`} onClick={toggleMenu}></div>
        </>
    );
};