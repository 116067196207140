import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../components/Logo'
import ThemeItem from '../components/ThemeItem'

// Images
import msImg from '../img/bg/main-img.webp'
import client1 from '../img/clients/bajaao.svg'
import client2 from '../img/clients/fable-street.svg'
import client3 from '../img/clients/john-jacobs.svg'
import client4 from '../img/clients/mcaffeine.svg'
import client5 from '../img/clients/nush.svg'
import client6 from '../img/clients/star-struck.svg'
import client7 from '../img/clients/vahdam.svg'
import client8 from '../img/clients/yoga-bar.svg'
import demo1 from '../img/themes/demo(1).webp'
import demo1Thumb from '../img/themes/demo(1).avif'
import demo2 from '../img/themes/demo(2).webp'
import demo2Thumb from '../img/themes/demo(2).avif'
import demo3 from '../img/themes/demo(3).webp'
import demo3Thumb from '../img/themes/demo(3).avif'
import demo4 from '../img/themes/demo(4).webp'
import demo4Thumb from '../img/themes/demo(4).avif'

export default function index() {
  return (
    <>
    <section className="main-sction">
      <div className="container-lg cl-custome">
        <div className="ms-inner">
          <Logo className="ms-logo" />
          <div className="ms-heading">Build your own news <br/> website in 3 easy steps</div>
          <div className="ms-sub-heading">Newscart is trusted by millions of businesses worldwide</div>
          <div className="ms-form-bx">
            <input type='email' placeholder=" " />
            <label>Enter your email address</label>
            <div className='common-btn-item cbi-fill cbi-ms-btn'>Submit</div>
          </div>
          <div className="ms-sub-small">By entering your email, you agree to receive marketing emails from Newscart.</div>
          <img className="ms-img" src={msImg} alt="" />
        </div>
      </div>
    </section>

    <section className='pt-5 py-5 mt-4'>
      <div className="container-lg cl-custome">
        <div className="row row-gap-4">

          <div className="col-lg-4 col-sm-6">
            <div className="card-item">
              <div className="card-i-img">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" aria-hidden="true" height="64" width="64"><path fill="currentColor" fillRule="evenodd" d="M20 18h4v3h-3v3h-3v-4a2 2 0 0 1 2-2Zm7 3h5v-3h-5v3Zm-3 18v1a1 1 0 0 1-1 1h-3a2 2 0 0 1-2-2v-4h3v3h2a1 1 0 0 1 1 1Zm-6-7h3v-5h-3v5Zm14.841.766c-.358.124-.655.38-.832.714l-1.426 2.695-1.607-7.369 6.533 3.029-2.668.931Zm7.29-2.096-12.634-5.858a1.5 1.5 0 0 0-2.096 1.681l3.126 14.326a1.5 1.5 0 0 0 2.791.382l3.067-5.797 5.609-1.957a1.502 1.502 0 0 0 .137-2.777ZM41 20v3a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-2h-3v-3h4a2 2 0 0 1 2 2ZM14 8h24V5H14v3ZM7 8h4V5H7v3Zm34-3a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v31a3 3 0 0 0 3 3h6a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H7V11h31v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8h-.021c.01-1.235.021-3 .021-3Z" clipRule="evenodd"></path></svg>
              </div>
              <div className="card-i-heading">① Choose & customise a theme</div>
              <div className="card-i-text">Customize the design of your shop from over thousands of themes. No design experience or programming skills required.</div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="card-item">
              <div className="card-i-img">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" aria-hidden="true" height="64" width="64"><path fill="currentColor" fillRule="evenodd" d="M39.005 11.005h-5v-5c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v5h-5c-.83 0-1.5.67-1.5 1.5 0 .82.67 1.5 1.5 1.5h5v5c0 .82.67 1.5 1.5 1.5.82 0 1.5-.68 1.5-1.5v-5h5c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5ZM37.995 24h-10.99A3.004 3.004 0 0 0 24 27.004v10.991A3.005 3.005 0 0 0 27.005 41h10.99a3.006 3.006 0 0 0 3.006-3.005v-10.99A3.005 3.005 0 0 0 37.996 24Zm0 3 .006.004L37.996 38 27 37.995 27.005 27h10.99Zm-20-3H7.006A3.004 3.004 0 0 0 4 27.004v10.991A3.005 3.005 0 0 0 7.005 41h10.99a3.006 3.006 0 0 0 3.006-3.005v-10.99A3.005 3.005 0 0 0 17.996 24Zm0 3 .006.004L17.996 38 7 37.995 7.005 27h10.99Zm0-23H7.006A3.004 3.004 0 0 0 4 7.004v10.991A3.005 3.005 0 0 0 7.005 21h10.99a3.006 3.006 0 0 0 3.006-3.005V7.005A3.005 3.005 0 0 0 17.996 4Zm0 3 .006.004L17.996 18 7 17.995 7.005 7h10.99Z" clipRule="evenodd"></path></svg>
              </div>
              <div className="card-i-heading">② Add products</div>
              <div className="card-i-text">Add your products to the shop. List your eye-catching products with the best photos, price and descriptions.</div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="card-item">
              <div className="card-i-img">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" aria-hidden="true" height="64" width="64"><path fill="currentColor" fillRule="evenodd" d="M40.5 22a1.5 1.5 0 0 0-1.5 1.5V38H7.951C17 35 28 26 31 20.079V24.5a1.5 1.5 0 0 0 3 0v-8a1.5 1.5 0 0 0-1.5-1.5h-8a1.5 1.5 0 0 0 0 3h4.326C26 24 13 34 5 36.057V11h19a1.5 1.5 0 1 0 0-3H5a3 3 0 0 0-3 3v27a3 3 0 0 0 3 3h34a3 3 0 0 0 3-3V23.5a1.5 1.5 0 0 0-1.5-1.5Zm1-7h-2a1.5 1.5 0 1 0 0 3h2a1.5 1.5 0 1 0 0-3Zm-10-5A1.5 1.5 0 0 0 33 8.5v-2a1.5 1.5 0 1 0-3 0v2a1.5 1.5 0 0 0 1.5 1.5Zm4.939 1.561a1.502 1.502 0 0 1 0-2.122l2-2a1.502 1.502 0 0 1 2.122 0 1.502 1.502 0 0 1 0 2.122l-2 2A1.495 1.495 0 0 1 37.5 12c-.384 0-.768-.146-1.061-.439Z" clipRule="evenodd"></path></svg>
              </div>
              <div className="card-i-heading">③ Start selling</div>
              <div className="card-i-text">Set up payments and shipping, and start selling.</div>
            </div>
          </div>

        </div>
      </div>
    </section>
    
    <section className="pt-5 py-5">
      <div className="container-lg cl-custome">
        <div className="common-heading-bx pb-4">
          <div className="common-heading">Themes</div>
          <div className="common-sub-heading">Made with core features you can easily customize—no coding needed. Get started fast with templates for your industry.</div>
        </div>
        <div className="row row-gap-5">

          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <ThemeItem 
              name="King" 
              price="$400" 
              mainImage={demo1} 
              thumbImage={demo1Thumb} 
              review="75% (4)" 
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <ThemeItem 
              name="Madrid" 
              price="$400" 
              mainImage={demo2} 
              thumbImage={demo2Thumb} 
              review="75% (4)" 
            />
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <ThemeItem 
              name="Motion" 
              price="$400" 
              mainImage={demo3} 
              thumbImage={demo3Thumb} 
              review="75% (4)" 
            />
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <ThemeItem 
              name="Vision" 
              price="$400" 
              mainImage={demo4} 
              thumbImage={demo4Thumb} 
              review="75% (4)" 
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <ThemeItem 
              name="King" 
              price="$400" 
              mainImage={demo1} 
              thumbImage={demo1Thumb} 
              review="75% (4)" 
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <ThemeItem 
              name="Madrid" 
              price="$400" 
              mainImage={demo2} 
              thumbImage={demo2Thumb} 
              review="75% (4)" 
            />
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <ThemeItem 
              name="Motion" 
              price="$400" 
              mainImage={demo3} 
              thumbImage={demo3Thumb} 
              review="75% (4)" 
            />
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <ThemeItem 
              name="Vision" 
              price="$400" 
              mainImage={demo4} 
              thumbImage={demo4Thumb} 
              review="75% (4)" 
            />
          </div>

        </div>
      </div>
    </section>
    
    <section className='pt-5 py-5'>
      <div className="container-lg cl-custome">
        <div className="clients-outer">
          <div className="clients-bx">
            
            <div className="client-item">
              <img src={client1} alt="" />
            </div>

            <div className="client-item">
              <img src={client4} alt="" />
            </div>

            <div className="client-item">
              <img src={client8} alt="" />
            </div>

            <div className="client-item">
              <img src={client5} alt="" />
            </div>

            <div className="client-item">
              <img src={client3} alt="" />
            </div>

            <div className="client-item">
              <img src={client7} alt="" />
            </div>

            <div className="client-item">
              <img src={client2} alt="" />
            </div>

            <div className="client-item">
              <img src={client6} alt="" />
            </div>

          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container-lg cl-custome">
        <div className="common-text-layout">
          <div className='ctl-text'>“Newscart is the perfect solution for my newscart website, which both easy to build and easy to maintain.”</div>
          <div className='common-btn-item cbi-fill cbi-big'>Start free trial</div>
        </div>
      </div>
    </section>

    <section>
      <div className="container-lg cl-custome">
        <div className="page-bottom-links">
          <Link to="/terms-and-conditions" className='pbl-item'>Terms of Service</Link>
          <Link to="/privacy-policy" className='pbl-item'>Privacy Policy</Link>
          <Link to="/sitemap" className='pbl-item'>Sitemap</Link>
        </div>
      </div>
    </section>
    </>
  )
}
