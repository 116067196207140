import React from 'react';
import { Link } from "react-router-dom";
import Logo from '../../components/Logo';

export default function HeaderSignInUp({ to, onClick }) {
    return (
        <>
            <div className={`signinup-screen-header`}>
                {(to || onClick) &&
                    <Link to={to} onClick={onClick} className="SignInUpBackBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                        </svg>
                    </Link>
                }
                <Logo className="signinup-logo-bx" />
            </div>
            <div className="gradient-background-bx">
                <div className="gb-inner">
                    <div className="gb-shape gb-s-1"></div>
                    <div className="gb-shape gb-s-2"></div>
                </div>
                <div className="gb-noice"></div>
            </div>
        </>
    )
}
