import React from 'react'
import {Link} from "react-router-dom";

export default function PageAbout() {
  return (
    <>
        <section className="relative">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ About Us</div>
                    </div>
                    <div data-aos="fade-up" className="tlm-heading">About Us</div>
                </div>
            </div>
        </section>

        <section className="pb-5">
            <div className="container-lg cl-custome">
                <div className="common-section about-maxw">

                    <div className="common-heading-bx">
                        <div className="common-heading">The first Newscart store was our own</div>
                        <div className="common-sub-heading">Over a decade ago, we started a store to sell snowboards online. None of the News solutions at the time gave us the control we needed to be successful—so we built our own. Today, businesses of all sizes use Newscart, whether they’re selling online, in retail stores, or on-the-go.</div>
                    </div>

                    <div className="common-heading-bx pt-4">
                        <div className="common-heading">Making commerce better for everyone</div>
                        <div className="common-sub-heading">We help people achieve independence by making it easier to start, run, and grow a business. We believe the future of commerce has more voices, not fewer, so we’re reducing the barriers to business ownership to make commerce better for everyone.</div>
                    </div>

                    <div className="common-heading-bx pt-4">
                        <div className="common-heading">Creating a community for impact</div>
                        <div className="common-sub-heading">Newscart has grown from 5 people in a coffee shop to over 10,000 across the globe. With millions of businesses powered by Newscart, we invest in our people and our communities. Our Social Impact initiatives focus on enabling an equitable future by building products and programs to support our team and merchants.</div>
                    </div>

                    <div className="common-heading-bx pt-4">
                        <div className="common-heading">We’re building a 100-year company</div>
                        <div className="common-sub-heading">Newscart builds for the long term, and that means investing in our planet so that we can future proof Newscart and help our merchants future proof their businesses, too. Our Sustainability Fund includes kickstarting the carbon removal market and choosing renewable energy, reducing and removing our carbon emissions, and creating solutions for our merchants to do the same.</div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}
