import React from 'react'
import { Link, NavLink } from 'react-router-dom'

// Design Files
import Socialicon from '../../components/Socialicon';
import Logo from '../../components/Logo'

// Images Common Files
import sms from "../../img/icons/sms.svg";
import call from "../../img/icons/call.svg";

export default function Footer() {
  

//   const footercontactinfo = [
//     {
//         contactClassName: "col-md-12 col-sm-12 whatsapp-link",
//         contactName: "whatsapp",
//         contactImg: Img.whatsapp,
//         contactData: "whatsapp",
//         contactLink: "https://api.whatsapp.com/send?phone=+971562520783&text=Hello",
//     },
//     {
//         contactClassName: "col-md-2 col-sm-6",
//         contactName: "Email Us",
//         contactImg: sms,
//         contactData: "info@newscart.com",
//         contactLink: "mailto:info@newscart.com",
//     },
//     {
//         contactClassName: "col-md-2 col-sm-6",
//         contactName: "Call Us",
//         contactImg: call,
//         contactData: "+91 81281 81082",
//         contactLink: "tel:+918128181082",
//     },
// ]

const AddressInfo = [
  {
      contactClassName: "col-12 mb-2",
      contactName: "",
      contactData: "1220, Shilvalik Shilp, ISKON Cross Road, Satellite, Ahmedabad: 380015.",
      contactLink: "",
  },
]

  return (
    <div className="custome-footer">
       <div className="pt-5 pb-2 mx-3">
          <div className='col-md-12 mb-md-0 mb-4'>
            <Logo className="cf-logo" />
            {/* <div className="footer-heading">Explore</div> */}
            <div className="footer-links-bx">
              <NavLink to="/" className="footer-link">Home</NavLink>
              <NavLink to="/about" className="footer-link">About Us</NavLink>
              <Link className="footer-link">Blog</Link>
              <NavLink to="/privacy-policy" className="footer-link">Privacy Policy</NavLink>
              <NavLink to="/terms-and-conditions" className="footer-link">Terms & Conditions</NavLink>
              <NavLink to="/contact" className="footer-link">Contact Us</NavLink>
              <NavLink to="/sitemap" className="footer-link">Sitemap</NavLink>
            </div>
          </div>

          <div className='col-md-12 mb-md-0 mb-4'>
            {/* <div className="footer-heading">Contact Info</div> */}
              <div className="row mb-3 justify-content-center">
                <div className="col-md-12 col-sm-12 footer-links-bx">
                  <Link className="footer-link fci-nowrap" to="mailto:info@newscart.com">
                    <img src={sms} alt="" />
                    <span>info@newscart.com</span>
                  </Link>
                  <Link className="footer-link" to="tel:+918128181082">
                    <img src={call} alt="" />
                    <span>+91 81281 81082</span>
                  </Link>
                </div>

                

                {/* { footercontactinfo.map((item, index) => ( 
                  <div className={item.contactClassName} key={index}>
                    <Link className="footer-contact-info fci-nowrap" to={item.contactLink}>
                      <img src={item.contactImg} alt="" />
                      {item.contactData}
                    </Link>
                  </div>
                ))} */}

                <div className='col-sm-12'>
                  {/* <div className="footer-heading">Address</div> */}
                    <div className="row">
                      { AddressInfo.map((item, index) => ( 
                        <div className={item.contactClassName} key={index}>
                          <div className="footer-contact-lable">
                            {( item.contactImg && <img src={item.contactImg} alt="" /> )}
                            {item.contactName}
                          </div>
                          <Link className="footer-contact-info" to={item.contactLink}>
                            {item.contactData}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
              </div>
              <Socialicon className={`social-icon si-black  justify-content-center `} />
          </div>

          <div className={`footer-bottom justify-content-center `}>
            <div className="copyright">© 2024 All Rights Reserved By Newscart</div>
            {/* <div className="mede-with">Made with
                <span className="icons-box-made">
                    <div className="fa fa-heart-o" aria-hidden="true"></div>
                    <div className="fa fa-heart" aria-hidden="true"></div>
                </span>
                by: <a href="https://pmcommu.com/" target="_blank" rel="noreferrer">PM Communications</a>
            </div> */}
          </div>

      </div>
  </div>
  )
}
