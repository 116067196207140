import React from 'react'
import { Link } from 'react-router-dom';

// Common Field
import Input from '../../components/commonfield/Input'

// Images Common File
import Icons from '../../components/icons';
import HeaderSignInUp from './HeaderSignInUp';
import Logo from '../../components/Logo';

export default function PageForgot() {

  const handleOnChange = () => {

  }

  return (
    <section className="signinup-main-box">

        <HeaderSignInUp to="/signin" />

        <div className="signinup-screen">
            <Logo className="signinup-logo-bx" />
             
            <div className="signinup-header">
              <div className="screen-hed">Forgot Password</div>
              <div className="screen-pera">Please enter your email to help change your password.</div>
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-12">
                   <Input heading="Your email" type="email" placeholder="Enter email address" onChange={handleOnChange} errorMeg="Email address is required" />
                </div>

                <div className="col-12">
                  <div className="common-submit-btn">Reset Password</div>
                  <div className="lsb-loader-btn"><Icons.LoadingSvg/> Please wait ....</div>
                </div>

                <div class="col-12">
                    <div class="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                      Back to 
                      <Link class="signinup-link-two" to="/signin">Sign in</Link>
                    </div>
                </div>

              </div>
            </form>
        </div>

    </section>
  )
}