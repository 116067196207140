import React from 'react'

// Slider Code
import Slider from 'react-slick';

export default function ThemeSlider({children}) {
    const themeSlider = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide:true,
        speed: 500,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: "6%",
                }
            }
        ]
    };
    return (
        <Slider className={`slick-hover mt-4`} {...themeSlider}>
            {children}
        </Slider>
    )
}
