import React from 'react'
import { Link } from "react-router-dom";

export default function PagePrivacy() {
  return (
    <>
        <section className="relative">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx pb-3">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ Privacy Policy</div>
                    </div>
                    <div className="tlm-heading">Privacy Policy</div>
                </div>
            </div>
      </section>

      <section class="pb-5">
        <div class="container-lg cl-custome legal-content">
            <div class="main-sub-lable">Introduction</div>
            <p>In our mission to make commerce better for everyone at Newscart, we collect and use information about you, our</p>
            <ul class="ul-dick">
                <li><a href="/">merchants using Newscart</a> to power your business</li>
                <li><a href="/">customers</a> who shop at a Newscart-powered business</li>
                <li><a href="/">partners</a> who develop apps for merchants to use, build stores on behalf of merchants, refer potential entrepreneurs to Newscart, or otherwise help merchants operate or improve their Newscart-powered business</li>
                <li>visitors to <a href="/">Newscart’s websites</a>, or anyone contacting <a href="/">Newscart support</a></li>
            </ul>
            <p>This Privacy Policy will help you better understand how we collect, use, and share your personal information. If we change our privacy practices, we may update this privacy policy. If any changes are significant, we will let you know (for example, through the Newscart admin or by email).</p>
        
            <div class="main-sub-lable">Our values</div>
            <p>Trust is the foundation of the Newscart platform and includes trusting us to do the right thing with your information. Three main values guide us as we develop our products and services. These values should help you better understand how we think about your information and privacy.</p>
            
            <div class="main-sub-lable">Your information belongs to you</div>
            <p>We carefully analyze what types of information we need to provide our services, and we try to limit the information we collect to only what we really need. Where possible, we delete or anonymize this information when we no longer need it. When building and improving our products, our engineers work closely with our privacy and security teams to build with privacy in mind. In all of this work our guiding principle is that your information belongs to you, and we aim to only use your information to your benefit.</p>
            
            <div class="main-sub-lable">We protect your information from others</div>
            <p>If a third party requests your personal information, we will refuse to share it unless you give us permission or we are legally required. When we are legally required to share your personal information, we will tell you in advance, unless we are legally forbidden.</p>
        
        </div>
      </section>
    </>
  )
}
