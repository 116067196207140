import React, { useState } from 'react';

const Pricing = () => {
  const [activeSize, setActiveSize] = useState('basic'); // Define activeSize and setActiveSize
  const [activeTab] = useState('yearly'); // State for tab selection

  const sizes = [
    { id: 'basic', label: 'Basic' },
    { id: 'shopify', label: 'Shopify' },
    { id: 'advanced', label: 'Advanced' },
    { id: 'plus', label: 'Plus' },
  ];

  const plans = {
    basic: {
      price: '₹999',
      description: 'For solo entrepreneurs',
      cardRates: '2% 3rd-party payment providers',
      features: [
        '10 inventory locations',
        '24/7 chat support',
        'Localized global selling (3 markets)',
        'POS Lite',
      ],
    },
    shopify: {
      price: '₹1,499',
      description: 'For small teams',
      cardRates: '1% 3rd-party payment providers',
      features: [
        '10 inventory locations',
        '24/7 chat support',
        'Localized global selling (3 markets)',
        '5 additional staff accounts',
        'POS Lite',
      ],
    },
    advanced: {
      price: '₹1,999',
      description: 'As your business scales',
      cardRates: '0.8% 3rd-party payment providers',
      features: [
        'Custom reports and analytics',
        '10 inventory locations',
        'Enhanced 24/7 chat support',
        '15 additional staff accounts',
        '10x checkout capacity',
      ],
    },
    plus: {
      price: '₹2,499',
      description: 'For more complex businesses',
      cardRates: '0.6% 3rd-party payment providers',
      features: [
        'Custom reports and analytics',
        '10 inventory locations',
        'Enhanced 24/7 chat support',
        '15 additional staff accounts',
        '10x checkout capacity',
      ],
    },
  };

  return (
    <>

        <section className=" pb-5 pt-4 bg-pricing-hero-next">
            <div className="container-lg cl-custome py-5">
                <div className="text-plans">Plans & pricing</div>
                <div className="heading-start">Start for free, then enjoy<br /> your first month for ₹20</div>
                <div className="text-sub-choose">Choose the best plan for your business. Change plans as you grow.</div>
            </div>
        </section>

        <section className="pb-5 pt-5 tabs-sect">
            <div className="container-lg cl-custome">
                <div className="text-center">
                    <div className="main-p-my">
                        <div className="main-p-month">Pay monthly</div>
                        <div className="main-p-year">Pay yearly (save 25%)*</div>
                    </div>
                </div>

                {activeTab === 'monthly' && (
                    <div className="row">
                        <div class="col-lg-3 col-md-6 mt-3 mt-md-0">
                            <div className="main-pricing-bx">
                                    <div className="main-i-text">₹20 for your first month</div>
        
                                    <div className="grid">
                                        <div className="px-4">
                                            <div className="main-hea-secn">
                                                <div className="flex flex-col w-full">
                                                    <div className="main-heading-pricing">Basic</div>
                                                </div>
        
                                                <div className="text-right">
                                                    <span className=" bg-starter-green py-1">Most Popular</span>
                                                </div>
                                            </div>
                                            <p className="main-head-text">For solo entrepreneurs</p>
                                        </div>
        
                                        <div className="pt-2 px-4 billed-text">
                                            <div className="flex-wrap">
                                                <span className="d-flex flex-nowrap">
                                                    <span className="once-yearly pr-1">₹999</span>
                                                    <sub className="bil-onc-yea">
                                                        <span>INR</span>
                                                        <span>/month</span>
                                                    </sub>
                                                </span>
                                                <span className="once-y-text">billed once yearly</span>
                                            </div>
                                            <hr class="my-4"></hr>
                                        </div>
                                    </div>
        
                                    <div className="card-rates-text">
                                        <p className="mb-3 card-rat-head">Card rates starting at</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>2% 3rd-party payment providers</li>
                                        </ul>
                                    </div>
                                    <div className="card-rates-text pb-4">
                                        <p className="mb-2 mt-3 card-rat-head">Standout features</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>  10 inventory locations</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>24/7 chat support</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Localized global selling (3 markets)</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>POS Lite</li>
                                            <li></li>
                                        </ul>
                                    </div>
        
                                    <div className="card-rates-text pb-5">
                                        <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                                    </div>
                            </div>
                        </div>
    
                        <div class="col-lg-3 col-md-6 mt-3 mt-md-0">
                            <div className="main-pricing-bx">
                                    <div className="main-i-text">₹20 for your first month</div>
        
                                    <div className="grid">
                                        <div className="px-4">
                                            <div className="main-hea-secn">
                                                <div className="flex flex-col w-full">
                                                    <div className="main-heading-pricing">Newscart</div>
                                                </div>
        
                                                {/* <div className="text-right">
                                                    <span className=" bg-starter-green py-1">Most Popular</span>
                                                </div> */}
                                            </div>
                                            <p className="main-head-text">For small teams</p>
                                        </div>
        
                                        <div className="pt-2 px-4 billed-text">
                                            <div className="flex-wrap">
                                                <span className="d-flex flex-nowrap">
                                                    <span className="once-yearly pr-1">₹1,499</span>
                                                    <sub className="bil-onc-yea">
                                                        <span>INR</span>
                                                        <span>/month</span>
                                                    </sub>
                                                </span>
                                                <span className="once-y-text">billed once yearly</span>
                                            </div>
                                            <hr class="my-4"></hr>
                                        </div>
                                    </div>
        
                                    <div className="card-rates-text">
                                        <p className="mb-3 card-rat-head">Card rates starting at</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>1% 3rd-party payment providers</li>
                                        </ul>
                                    </div>
                                    <div className="card-rates-text pb-4 pt-2">
                                        <p className="mb-2 mt-3 card-rat-head">Standout features</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>  10 inventory locations</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>24/7 chat support</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Localized global selling (3 markets)</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>5 additional staff accounts</li>
                                            {/* <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>POS Lite</li> */}
                                        </ul>
                                    </div>
        
                                    <div className="card-rates-text pb-5 pt-5 mt-5">
                                        <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                                    </div>
                            </div>
                        </div>
    
                        <div class="col-lg-3 col-md-6 mt-3 mt-md-0">
                            <div className="main-pricing-bx">
                                    <div className="main-i-text">₹20 for your first month</div>
        
                                    <div className="grid">
                                        <div className="px-4">
                                            <div className="main-hea-secn">
                                                <div className="flex flex-col w-full">
                                                    <div className="main-heading-pricing">Advanced</div>
                                                </div>
        
                                                {/* <div className="text-right">
                                                    <span className=" bg-starter-green py-1">Most Popular</span>
                                                </div> */}
                                            </div>
                                            <p className="main-head-text">As your business scales</p>
                                        </div>
        
                                        <div className="pt-2 px-4 billed-text">
                                            <div className="flex-wrap">
                                                <span className="d-flex flex-nowrap">
                                                    <span className="once-yearly pr-1">₹1,999</span>
                                                    <sub className="bil-onc-yea">
                                                        <span>INR</span>
                                                        <span>/month</span>
                                                    </sub>
                                                </span>
                                                <span className="once-y-text">billed once yearly</span>
                                            </div>
                                            <hr class="my-4"></hr>
                                        </div>
                                    </div>
        
                                    <div className="card-rates-text">
                                        <p className="mb-3 card-rat-head">Card rates starting at</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>0.6% 3rd-party payment providers</li>
                                        </ul>
                                    </div>
                                    <div className="card-rates-text pb-4">
                                        <p className="mb-2 mt-3 card-rat-head">Standout features</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Custom reports and analytics</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>10 inventory locations</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Enhanced 24/7 chat support</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>15 additional staff accounts</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>10x checkout capacity</li>
                                        </ul>
                                    </div>
        
                                    <div className="card-rates-text pb-5">
                                        <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                                    </div>
                            </div>
                        </div>
    
                        <div class="col-lg-3 col-md-6 mt-3 mt-md-0">
                            <div className="main-pricing-bx">
                                    <div className="main-i-text">₹20 for your first month</div>
        
                                    <div className="grid">
                                        <div className="px-4">
                                            <div className="main-hea-secn">
                                                <div className="flex flex-col w-full">
                                                    <div className="main-heading-pricing">Plus</div>
                                                </div>
        
                                                {/* <div className="text-right">
                                                    <span className=" bg-starter-green py-1">Most Popular</span>
                                                </div> */}
                                            </div>
                                            <p className="main-head-text">For more complex businesses</p>
                                        </div>
        
                                        <div className="pt-2 px-4 billed-text">
                                            <div className="flex-wrap">
                                                <span className="d-flex flex-nowrap">
                                                    <span className="once-yearly pr-1">₹2,499</span>
                                                    <sub className="bil-onc-yea">
                                                        <span>INR</span>
                                                        <span>/month</span>
                                                    </sub>
                                                </span>
                                                <span className="once-y-text">billed once yearly</span>
                                            </div>
                                            <hr class="my-4"></hr>
                                        </div>
                                    </div>
        
                                    <div className="card-rates-text">
                                        <p className="mb-3 card-rat-head">Card rates</p>
                                        <ul>
                                            <li>Competitive rates for high-volume merchants</li>
                                        </ul>
                                    </div>
                                    <div className="card-rates-text pb-4">
                                        <p className="mb-2 mt-3 card-rat-head">Standout features</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg> Custom reports and analytics</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>200 inventory locations</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Priority 24/7 phone support</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Unlimited staff accounts</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Sell wholesale/B2B</li>
                                        </ul>
                                    </div>
        
                                    <div className="card-rates-text pb-5">
                                        <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                                    </div>
                            </div>
                        </div>
                    </div>
                )}
                
               {activeTab === 'yearly' && (
                    <div className="row">
                        <div class="col-lg-3 col-md-6 mt-3 mt-md-0">
                            <div className="main-pricing-bx">
                                    <div className="main-i-text">₹20 for your first month</div>
        
                                    <div className="grid">
                                        <div className="px-4">
                                            <div className="main-hea-secn">
                                                <div className="flex flex-col w-full">
                                                    <div className="main-heading-pricing">Basic</div>
                                                </div>
        
                                                <div className="text-right">
                                                    <span className=" bg-starter-green py-1">Most Popular</span>
                                                </div>
                                            </div>
                                            <p className="main-head-text">For solo entrepreneurs</p>
                                        </div>
        
                                        <div className="pt-2 px-4 billed-text">
                                            <div className="flex-wrap">
                                                <span className="d-flex flex-nowrap">
                                                    <span className="once-yearly pr-1">₹999</span>
                                                    <sub className="bil-onc-yea">
                                                        <span>INR</span>
                                                        <span>/month</span>
                                                    </sub>
                                                </span>
                                                <span className="once-y-text">billed once yearly</span>
                                            </div>
                                            <hr class="my-4"></hr>
                                        </div>
                                    </div>
        
                                    <div className="card-rates-text">
                                        <p className="mb-3 card-rat-head">Card rates starting at</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>2% 3rd-party payment providers</li>
                                        </ul>
                                    </div>
                                    <div className="card-rates-text pb-4">
                                        <p className="mb-2 mt-3 card-rat-head">Standout features</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>  10 inventory locations</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>24/7 chat support</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Localized global selling (3 markets)</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>POS Lite</li>
                                            <li></li>
                                        </ul>
                                    </div>
        
                                    <div className="card-rates-text pb-5">
                                        <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                                    </div>
                            </div>
                        </div>
    
                        <div class="col-lg-3 col-md-6 mt-3 mt-md-0">
                            <div className="main-pricing-bx">
                                    <div className="main-i-text">₹20 for your first month</div>
        
                                    <div className="grid">
                                        <div className="px-4">
                                            <div className="main-hea-secn">
                                                <div className="flex flex-col w-full">
                                                    <div className="main-heading-pricing">Newscart</div>
                                                </div>
        
                                                {/* <div className="text-right">
                                                    <span className=" bg-starter-green py-1">Most Popular</span>
                                                </div> */}
                                            </div>
                                            <p className="main-head-text">For small teams</p>
                                        </div>
        
                                        <div className="pt-2 px-4 billed-text">
                                            <div className="flex-wrap">
                                                <span className="d-flex flex-nowrap">
                                                    <span className="once-yearly pr-1">₹1,499</span>
                                                    <sub className="bil-onc-yea">
                                                        <span>INR</span>
                                                        <span>/month</span>
                                                    </sub>
                                                </span>
                                                <span className="once-y-text">billed once yearly</span>
                                            </div>
                                            <hr class="my-4"></hr>
                                        </div>
                                    </div>
        
                                    <div className="card-rates-text">
                                        <p className="mb-3 card-rat-head">Card rates starting at</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>1% 3rd-party payment providers</li>
                                        </ul>
                                    </div>
                                    <div className="card-rates-text pb-4 pt-2">
                                        <p className="mb-2 mt-3 card-rat-head">Standout features</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>  10 inventory locations</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>24/7 chat support</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Localized global selling (3 markets)</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>5 additional staff accounts</li>
                                            {/* <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>POS Lite</li> */}
                                        </ul>
                                    </div>
        
                                    <div className="card-rates-text pb-5 pt-4">
                                        <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                                    </div>
                            </div>
                        </div>
    
                        <div class="col-lg-3 col-md-6 mt-3 mt-md-0">
                            <div className="main-pricing-bx">
                                    <div className="main-i-text">₹20 for your first month</div>
        
                                    <div className="grid">
                                        <div className="px-4">
                                            <div className="main-hea-secn">
                                                <div className="flex flex-col w-full">
                                                    <div className="main-heading-pricing">Advanced</div>
                                                </div>
        
                                                {/* <div className="text-right">
                                                    <span className=" bg-starter-green py-1">Most Popular</span>
                                                </div> */}
                                            </div>
                                            <p className="main-head-text">As your business scales</p>
                                        </div>
        
                                        <div className="pt-2 px-4 billed-text">
                                            <div className="flex-wrap">
                                                <span className="d-flex flex-nowrap">
                                                    <span className="once-yearly pr-1">₹1,999</span>
                                                    <sub className="bil-onc-yea">
                                                        <span>INR</span>
                                                        <span>/month</span>
                                                    </sub>
                                                </span>
                                                <span className="once-y-text">billed once yearly</span>
                                            </div>
                                            <hr class="my-4"></hr>
                                        </div>
                                    </div>
        
                                    <div className="card-rates-text">
                                        <p className="mb-3 card-rat-head">Card rates starting at</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>0.6% 3rd-party payment providers</li>
                                        </ul>
                                    </div>
                                    <div className="card-rates-text pb-4">
                                        <p className="mb-2 mt-3 card-rat-head">Standout features</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Custom reports and analytics</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>10 inventory locations</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Enhanced 24/7 chat support</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>15 additional staff accounts</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>10x checkout capacity</li>
                                        </ul>
                                    </div>
        
                                    <div className="card-rates-text pb-5">
                                        <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                                    </div>
                            </div>
                        </div>
    
                        <div class="col-lg-3 col-md-6 mt-3 mt-md-0">
                            <div className="main-pricing-bx">
                                    <div className="main-i-text">₹20 for your first month</div>
        
                                    <div className="grid">
                                        <div className="px-4">
                                            <div className="main-hea-secn">
                                                <div className="flex flex-col w-full">
                                                    <div className="main-heading-pricing">Plus</div>
                                                </div>
        
                                                {/* <div className="text-right">
                                                    <span className=" bg-starter-green py-1">Most Popular</span>
                                                </div> */}
                                            </div>
                                            <p className="main-head-text">For more complex businesses</p>
                                        </div>
        
                                        <div className="pt-2 px-4 billed-text">
                                            <div className="flex-wrap">
                                                <span className="d-flex flex-nowrap">
                                                    <span className="once-yearly pr-1">₹2,499</span>
                                                    <sub className="bil-onc-yea">
                                                        <span>INR</span>
                                                        <span>/month</span>
                                                    </sub>
                                                </span>
                                                <span className="once-y-text">billed once yearly</span>
                                            </div>
                                            <hr class="my-4"></hr>
                                        </div>
                                    </div>
        
                                    <div className="card-rates-text">
                                        <p className="mb-3 card-rat-head">Card rates</p>
                                        <ul>
                                            <li>Competitive rates for high-volume merchants</li>
                                        </ul>
                                    </div>
                                    <div className="card-rates-text pb-4">
                                        <p className="mb-2 mt-3 card-rat-head">Standout features</p>
                                        <ul>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg> Custom reports and analytics</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>200 inventory locations</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Priority 24/7 phone support</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Unlimited staff accounts</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>Sell wholesale/B2B</li>
                                        </ul>
                                    </div>
        
                                    <div className="card-rates-text pb-5">
                                        <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                                    </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>

        <div className="tabs-container plans-size">
            <div className="plans-account-size-btns">
                {sizes.map(size => (
                <div
                    key={size.id}
                    className={`pasb-item ${activeSize === size.id ? 'active' : ''}`}
                    onClick={() => setActiveSize(size.id)} // Use setActiveSize here
                >
                    {/* <span className="plans-accou-mon"></span> */}
                    {size.label}
                </div>
                ))}
            </div>

            <div className={`plans-table-responsive`} id={activeSize}>
                <div className="main-pricing-bx">
                <div className="main-i-text">₹20 for your first month</div>
                <div className="grid">
                    <div className="px-4">
                    <div className="main-hea-secn">
                        <div className="flex flex-col w-full">
                            <div className="main-heading-pricing">{sizes.find(size => size.id === activeSize)?.label}</div>
                            </div>
                        <div className="text-right">
                        <span className="bg-starter-green py-1">Most Popular</span>
                        </div>
                    </div>
                    <p className="main-head-text">{plans[activeSize]?.description}</p>
                    </div>

                    <div className="pt-2 px-4 billed-text">
                    <div className="flex-wrap">
                        <span className="d-flex flex-nowrap">
                        <span className="once-yearly pr-1">{plans[activeSize]?.price}</span>
                        <sub className="bil-onc-yea">
                            <span>INR</span>
                            <span>/month</span>
                        </sub>
                        </span>
                        <span className="once-y-text">billed once yearly</span>
                    </div>
                    <hr className="my-4" />
                    </div>
                </div>

                <div className="card-rates-text">
                    <p className="mb-3 card-rat-head">Card rates starting at</p>
                    <ul>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        {plans[activeSize]?.cardRates}
                    </li>
                    </ul>
                </div>

                <div className="card-rates-text pt-4">
                    <p className="mb-3 card-rat-head">Standout features</p>
                    <ul>
                    {plans[activeSize]?.features.map((feature, index) => (
                        <li key={index}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        {feature}
                        </li>
                    ))}
                    </ul>
                </div>

                <div className="card-rates-text pb-5">
                    <div className="common-btn-item cbi-white cbi-dms-btn">Buy Now</div>
                </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Pricing;
