import React from 'react'
import { Link } from "react-router-dom"

// Images Common Files
import phone from "../img/icons/phone.png";
import email from "../img/icons/email.png";
import location from "../img/icons/location.png";

// Common Field
import Input from '../components/commonfield/Input'
import Textarea from '../components/commonfield/textarea'

export default function contact() {
  
  const handleOnChange = () => {

  }

  return (
      <>
        <section className="relative">
          <div className="container-lg cl-custome">
            <div className="textlayout-main-bx pb-5">
              <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Contact Us</div>
              </div>
              <div className="tlm-heading">Contact Us</div>
            </div>
          </div>
        </section>

        <section className="pb-5 pt-2">
          <div className="container-lg cl-custome">
            <div className="row justify-content-center">
              <div class="col-lg-4 d-flex align-self-center col-md-6 mt-3 mt-md-0 order-2 order-md-1">
                <div class="row">
                  <div data-aos="fade-up" class="col-md-12 col-sm-6 aos-init aos-animate">
                    <a class="contact-info" href="tel:+918128181082">
                      <span class="contact-icon">
                      <img src={phone} alt="" />
                      </span>
                      <div class="contact-text">
                          <h6>Call Us</h6>
                          <p>+91 81281 81082</p>
                      </div>
                    </a>
                  </div>

                  <div data-aos="fade-up" class="col-md-12 col-sm-6 aos-init aos-animate">
                    <a class="contact-info" href="mailto:info@newscart.com">
                      <span class="contact-icon">
                        <img src={email} alt="" />
                      </span>
                      <div class="contact-text">
                        <h6>Email Us</h6>
                        <p>info@newscart.com</p>
                      </div>
                    </a>
                  </div>

                  <div data-aos="fade-up" class="col-md-12 col-sm-12 aos-init aos-animate">
                    <a class="contact-info" href="/">
                      <span class="contact-icon">
                        <img src={location} alt="" />
                      </span>
                      <div class="contact-text">
                        <h6>Address</h6>
                        <p>1220, Shilvalik Shilp, ISKON Cross Road, Satellite, Ahmedabad: 380015.</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <form class="row form-bx">
                  <div class="col-sm-6">
                    <Input heading="Your First name" type="text" placeholder="Enter first name" onChange={handleOnChange} errorMeg="First name is required" />
                  </div>

                  <div class="col-sm-6">
                    <Input heading="Your Last Name" type="text" placeholder="Enter last name" onChange={handleOnChange} errorMeg="First name is required" />
                  </div>
                 
                  <div class="col-sm-6">
                    <Input heading="Your Email ID" type="email" placeholder="Enter email id" onChange={handleOnChange} errorMeg="First name is required" />
                  </div>
                 
                  <div class="col-sm-6">
                    <Input heading="Your Phone Number" type="number" placeholder="Enter phone number" onChange={handleOnChange} errorMeg="First name is required" />
                  </div>
                 
                  <div class="col-sm-12">
                    <Textarea heading="Your Message" type="text"  placeholder="Enter your message here.." onChange={handleOnChange} errorMeg="First name is required" />
                  </div>

                  <div className="col-sm-12">
                    <div className="common-submit-btn">Submit</div>
                  </div>

                </form>
              </div>

            </div>
          </div>
        </section>

        <section>
          <iframe 
            title="Google map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14687.678027946635!2d72.5067927!3d23.0267275!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b4ebf293721%3A0x5495cb384b446dca!2sPm%20Communications!5e0!3m2!1sen!2sin!4v1727958501341!5m2!1sen!2sin" 
            width="100%" 
            height="450" 
            style={{ border: '0', marginBottom:'-6px' }} 
            allowFullScreen 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </section>
      </>
  )
}
