import React, { useContext } from 'react'
import { Link } from 'react-router-dom';

// Context File
import FlashMessageContext from '../../context/FlashMessageContext';

// Common Field
import Input from '../../components/commonfield/Input'
import PasswordInput from '../../components/commonfield/PasswordInput'
import CheckInput from '../../components/commonfield/CheckInput'

// Images Common File
import Icons from '../../components/icons';
import HeaderSignInUp from './HeaderSignInUp';
import Logo from '../../components/Logo';

export default function PageSignIn() {

  const handleOnChange = () => {

  }

  const { showFlashMessage } = useContext(FlashMessageContext);

  const handleSuccessClick = () => {
      showFlashMessage('This is a success message!', 'success');
  };

  const handleErrorClick = () => {
      showFlashMessage('This is an error message!', 'error');
  };


  return (
    <section className="signinup-main-box">
        <HeaderSignInUp to="/" />

        <div className="signinup-screen">

            <Link to="/" className="SignInUpBackBtn">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
            </Link>

            <Logo className="signinup-logo-bx" />

            <div className="signinup-header">
              <div className="screen-hed">Sign in to your account</div>
              {/* <div className="screen-pera">to access your account</div> */}
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-12">
                   <Input heading="Your email" type="email" placeholder="Enter email address" onChange={handleOnChange} errorMeg="Email is required" />
                </div>

                <div className="col-12">
                   <PasswordInput heading="Password" type="password" placeholder="Enter password" onChange={handleOnChange} errorMeg="Password is required" />
                </div>

                <div className="col-12">
                  <div className="signinup-link-outer-bx">
                    <CheckInput className="sgc-ci-18px mb-0" name="RememberCheck" id="RememberCheck" label="Remember me" onChange={handleOnChange}/>
                    <Link to="/forgot-password" className="signinup-link-two mt-0" >Forgot Password?</Link>
                  </div>
                </div>

                <div className="col-12">
                  <div onClick={handleSuccessClick} className="common-submit-btn">Sign in</div>
                  <div onClick={handleErrorClick} className="lsb-loader-btn"><Icons.LoadingSvg/> Please wait ....</div>
                </div>

                <div class="col-12">
                  <div class="signinup-link-outer-bx slob-fdc mb-0">If you don’t have an account you can<Link class="signinup-link-two" to="/signup">Register Here!</Link></div>
                </div>

              </div>
            </form>
        </div>
    </section>
  )
}