import React from 'react'
import { Link } from 'react-router-dom';

// Images Common File
import * as Img from './Img';

export default function Logo({className, src=Img.logo}) {
  return (
    <Link to="/" className={`${className}`}>
        <img className="main-logo" src={src} alt="" />
    </Link>
  )
}