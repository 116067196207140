import React from 'react'
import { Link } from "react-router-dom";

export default function PageTermsAndConditions() {
  return (
    <>
        <section className="relative">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx pb-3">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ Terms & Conditions</div>
                    </div>
                    <div className="tlm-heading">Terms & Conditions</div>
                </div>
            </div>
        </section>

        <section class="pb-5">
            <div class="container-lg cl-custome legal-content">
                <div class="main-sub-lable">1. Account Terms</div>
                <ul>
                    <li>To access and use the Services, you must register for a Newscart account (“Account”). To complete your Account registration, you must provide us with your full legal name, business address, phone number, a valid email address, and any other information indicated as required. Newscart may reject your application for an Account, or cancel an existing Account, for any reason, at our sole discretion.</li>
                    <li>You must be the older of: (i) 18 years, or (ii) at least the age of majority in the jurisdiction where you reside and from which you use the Services to open an Account.</li>
                    <li>You confirm that you are receiving any Services provided by Newscart for the purposes of carrying on a business activity and not for any personal, household or family purpose.</li>
                    <li>You acknowledge that Newscart will use the email address you provide on opening an Account or as updated by you from time to time as the primary method for communication with you (“Primary Email Address”). You must monitor the Primary Email Address you provide to Newscart and your Primary Email Address must be capable of both sending and receiving messages. Your email communications with Newscart can only be authenticated if they come from your Primary Email Address.</li>
                    <li>You are responsible for keeping your password secure. Newscart cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password. We may request additional security measures at any time and reserve the right to adjust these requirements at our discretion.</li>
                    <li>Technical support in respect of the Services is only provided to Newscart Users. Questions about the Terms of Service should be sent to Newscart Support.</li>
                    <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Services, or access to the Services without the express written permission by Newscart.</li>
                    <li>You agree not to work around, bypass, or circumvent any of the technical limitations of the Services, including to process orders outside Newscart’s Checkout, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services.</li>
                    <li>You agree not to access the Services or monitor any material or information from the Services using any robot, spider, scraper, or other automated means.</li>
                    <li>You understand that your Materials may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. “Materials” means Your Trademarks, copyright content, any products or services you sell through the Services (including description and price), and any photos, images, videos, graphics, written content, audio files, code, information, or other data provided or made available by you or your affiliates to Newscart or its affiliates.</li>
                </ul>
            </div>
        </section>
    </>
  )
}
