import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../components/Logo'
import Sidemenu from './sidemenu'
import { useMenu } from '../../context/MenuContext';

// Images Common File
import * as Img from '../../components/Img';

export default function Header() {

    const { toggleMenu } = useMenu();

    const [logoSrc, setLogoSrc] = useState('');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 350) {
                setLogoSrc(Img.icon); // Set your image source here
            } else {
                setLogoSrc(Img.logo); // Clear the image source when above 350px
            }
        };

        // Set initial state
        handleResize();
        
        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <header className="custom-header">

                <div className="container-lg cl-custome cl-1260-width ch-inner">

                    <div className="ch-left">
                        <Logo className="ch-logo" src={logoSrc} />
                    </div>

                    <div className="ch-right">

                        <div className="signinup-btn-bx">
                            <Link to="/signin" className="common-btn-item cbi-none">Sign In</Link>
                            <Link to="/signup" className="common-btn-item cbi-outline">Sign Up</Link>
                        </div>

                        <div className="menu-icon-bx" onClick={toggleMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        
                    </div>

                </div>

            </header>
            <Sidemenu />
        </>
    )
}