import React from 'react'
import { Link, NavLink  } from "react-router-dom"

const Cog6Svg = () => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );
};

export default function PageSitemap() {

  return (
    <>
        <section className="relative">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx pb-3">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ Sitemap</div>
                    </div>
                    <div className="tlm-heading">Sitemap</div>
                </div>
            </div>
        </section>

        <section className="pb-5 pt-2">
            <div className="container-lg cl-custome">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="sitemap-heading">Explore</div>
                        <ul className="sitemap-links-ul">
                            <li className="slu-li"><NavLink to="/" className="slul-link"><Cog6Svg/>Home</NavLink></li>
                            <li className="slu-li"><NavLink to="/about" className="slul-link"><Cog6Svg/>About Us</NavLink></li>
                            <li className="slu-li"><Link to="#" className="slul-link"><Cog6Svg/>Blog</Link></li>
                            <li className="slu-li"><Link to="#" className="slul-link"><Cog6Svg/>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="py-2"></div>

                    <div className="col-12">
                        <div className="sitemap-heading">Our Policies</div>
                        <ul className="sitemap-links-ul">
                            <li className="slu-li"><NavLink to="/privacy-policy" className="slul-link"><Cog6Svg/>Privacy Policy</NavLink></li>
                            <li className="slu-li"><NavLink to="/terms-and-conditions" className="slul-link"><Cog6Svg/>Terms & Conditions</NavLink></li>
                        </ul>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}
