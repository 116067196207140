import React from 'react';
import Slider from 'react-slick';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';

// ImageGallery Component
const ImageGallery = ({ images }) => {
    const settings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide:true,
      speed: 500,
      autoplaySpeed: 3000,
      responsive: [
          {
              breakpoint: 992,
              settings: {
                  slidesToShow: 3,
              }
          },
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 2,
              }
          },
          {
              breakpoint: 576,
              settings: {
                  slidesToShow: 2,
                  centerMode: true,
                  centerPadding: "6%",
              }
          }
      ]
  };

  return (
    <LightgalleryProvider>
      <Slider className="arrowtwo" {...settings}>
        {images.map((image, index) => (
          <LightgalleryItem group="group1" src={image.src} alt={image.alt} key={index}>
            <div className="theme-gallery-item tgi-slider">
              <div className="tgi-item">
                <img
                  src={image.thumb}
                  alt={image.alt}
                  className="gallery-image" // Custom class for styling
                  // onClick={() => console.log(`Image clicked: ${image.alt}`)} // Click handler
                  />
                <div className="tgi-zoom-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6" />
                  </svg>
                </div>
              </div>
            </div>
          </LightgalleryItem>
        ))}
      </Slider>
    </LightgalleryProvider>
  );
};

export default ImageGallery;
