import { createContext, useState, useEffect } from 'react';

// Create the context
const FlashMessageContext = createContext();

// Create the provider component
export const FlashMessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null);
    const [isPopupVisible, setPopupVisible] = useState(false); // Initial state should be false

    // Effect to handle popup visibility based on external isVisible prop
    useEffect(() => {
        let popupTimer;

        if (isPopupVisible) {
            // Start a timer to hide the popup after 3 seconds
            popupTimer = setTimeout(() => {
                setPopupVisible(false);
            }, 3000);
        }

        return () => {
            // Clear the timer when the component unmounts or when isVisible changes
            clearTimeout(popupTimer);
        };
    }, [isPopupVisible]);

    const showFlashMessage = (newMessage, type = 'success') => {
        setMessage({ text: newMessage, type });
        setPopupVisible(true);
    };

    const hideFlashMessage = () => {
        setPopupVisible(false);
    };

    return (
        <FlashMessageContext.Provider value={{ message, isPopupVisible, showFlashMessage, hideFlashMessage }}>
            {children}
        </FlashMessageContext.Provider>
    );
};

export default FlashMessageContext;