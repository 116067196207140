import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

// Context File
import { MenuProvider } from '../context/MenuContext';
import { FlashMessageProvider } from '../context/FlashMessageContext';

// Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../containers/Header/Header";

// Pages
import HomePage from "../containers/index";
import DetailPage from "../containers/detail";
import PageAbout from "../containers/PageAbout/PageAbout";
import PageContact from "../containers/contact";
import PagePricing from "../containers/pricing";

// Sign In & Up pages 
import PageSignIn from "../containers/PageSignup/PageSignIn";
import PageSignup from "../containers/PageSignup/PageSignUp";
import PageForgot from "../containers/PageSignup/PageForgot";
import PageReset from "../containers/PageSignup/PageReset";
import FlashMessage from "../components/FlashMessage";

// Sitemap
import PageSitemap from "../containers/PageSitemap/PageSitemap"

// PagreLagle pages 
import PagePrivacy from "../containers/PagreLagle/PagePrivacy";
import PageTermsAndConditions from "../containers/PagreLagle/PageTermsAndConditions";



// Footer section
import Footer from "../containers/Footer/Footer";

export const pages = [
  { path: "/", component: HomePage },
  { path: "/detail", component: DetailPage },
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },
  { path: "/pricing", component: PagePricing },

  // Sign In & Up pages links
  { path: "/signin", component: PageSignIn },
  { path: "/signup", component: PageSignup },
  { path: "/forgot-password", component: PageForgot },
  { path: "/reset-password", component: PageReset },

  // Sitemap
  { path: "/sitemap", component: PageSitemap },

  // PagreLagle pages 
  { path: "/privacy-policy", component: PagePrivacy },
  { path: "/terms-and-conditions", component: PageTermsAndConditions },

];

const MyRoutes = () => {

  const location = useLocation();

  const pathsToHideheader = ['/', '/signin', '/signup', '/forgot-password', '/reset-password'];
  
  const hideheader = pathsToHideheader.includes(location.pathname);

  return (
    <>
      <ScrollToTop />
      <Loader />
      {!hideheader && <Header /> }
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
      </Routes>
      {!hideheader && <Footer/>}
      <FlashMessage />
    </>
  );
};

function MainRoutes() {
  return (
    <MenuProvider>
      <FlashMessageProvider>
        <Router>
            <MyRoutes />
        </Router>
      </FlashMessageProvider>
    </MenuProvider>
  );
}

export default MainRoutes;