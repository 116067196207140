import React from 'react'
import { Link } from 'react-router-dom'

export default function ThemeItem({ className="", name, price, mainImage, thumbImage, review }) {
  return (
    <Link to="/detail" className={`themes-item-bx ${className}`}>
        <div className="tib-img-bx">
            <img className="tib-main-img" src={mainImage} alt="" />
            <div className="tib-responsive-img">
                <img src={thumbImage} alt="" />
                <div className="tib-r-left">
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        {name &&<div className="theme-name">{name}</div>}
        {price &&<div className="theme-price">{price} USD</div>}
        {review &&<div className="theme-review">  
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
            </svg>
            {review}
        </div>}
    </Link>
  )
}

