import React from 'react';
import ReactDOM from 'react-dom/client';

// Css Links
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import "lightgallery.js/dist/css/lightgallery.css";
import "./css/font-awesome.min.css";
import './css/style.css';
import './css/header.css'
import './css/sidemenu.css'
import './css/themecolor.css';
import './css/commonform.css'
import './css/signinup.css'
import './css/carditem.css';
import './css/themeitem.css';
import './css/home.css';
import './css/detail.css';
import './css/footer.css';
import './css/contact.css';
import './css/pricing.css';

// Scripts
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
