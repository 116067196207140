import React, { useContext } from 'react';

// Context File
import FlashMessageContext from '../context/FlashMessageContext';

// Css File
import '../css/flashmessage.css';

function FlashMessage() {
    const { message, isPopupVisible, hideFlashMessage } = useContext(FlashMessageContext);

    // Even if no message, render an empty container to preload the HTML structure
    const isSuccess = message?.type === 'success';

    return (
        <>
            <div className={`flash-message  ${isSuccess ? 'success-msg' : 'error-msg'} ${isPopupVisible ? 'fmp-message' : ''}`}>
                <div className="flash-message-inner">
                    <span className="msg-close" onClick={hideFlashMessage}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>
                    {isSuccess ? (
                        <svg className="flash-icon flash-success-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    ) : (
                        <svg className="flash-icon flash-error-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    )}
                    <div className="flash-text">{message?.text || ''}</div>
                    <div className="flash-time-line"></div>
                </div>
            </div>

            <div className={`flash-message-popup ${isPopupVisible ? 'fmp-active' : ''}`} onClick={hideFlashMessage}></div>
        </>
    );
}

export default FlashMessage;
