import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input'

// Common Field
import Input from '../../components/commonfield/Input'
import PasswordInput from '../../components/commonfield/PasswordInput'
import CheckInput from '../../components/commonfield/CheckInput'

// Images Common File
import Icons from '../../components/icons';
import HeaderSignInUp from './HeaderSignInUp';
import Logo from '../../components/Logo';

export default function PageSignUp() {
    
  // --- Sign Up Tabbing Js Start
  const [step, setStep] = useState('step1');

  const handleStepClick = (stepId) => {
    setStep(stepId);
  };
  // --- Sign Up Tabbing Js End

  const handleOnChange = () => {

  }

  return (
    <section className="signinup-main-box">

        {step === "step1" ? (
          <HeaderSignInUp to="/signin" />
        ) : step === "step2" ? (
          <HeaderSignInUp onClick={() => handleStepClick('step1')} />
        ) : null}

        <div className={`signinup-screen ${step === 'step1' ? '' : 'd-none'} `} id="step1">

            <Logo className="signinup-logo-bx" />

            <div className="signinup-header">
              <div className="screen-hed">Create an account</div>
              {/* <div className="screen-pera">to access your account</div> */}
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-6">
                   <Input heading="Your first name" type="text" placeholder="Enter first name" onChange={handleOnChange} errorMeg="First name is required" />
                </div>

                <div className="col-6">
                   <Input heading="Your last name" type="text" placeholder="Enter last name" onChange={handleOnChange} errorMeg="Lirst name is required" />
                </div>

                <div className="col-12">
                   <Input heading="Your email" type="email" placeholder="Enter email address" onChange={handleOnChange} errorMeg="Email is required" />
                </div>

                <div className="col-12">
                  <div class="sgr-outer-label">Phone number</div>
                  <div className='signinup-group sg-country-code'>
                    <PhoneInput
                      heading="Phone number"
                      international
                      placeholder="Enter phone number"
                      // errorMeg={errors?.phoneNumber} error={errors?.phoneNumber}
                      // value={phoneNumber}
                      defaultCountry="IN"
                      onChange={handleOnChange}/>
                      {/* {errors?.phoneNumber && <div className='form-error-msg'>{errors?.phoneNumber}</div>} */}
                  </div>
                </div>

                <div className="col-12">
                  <div className="signinup-link-outer-bx">
                    <CheckInput className="sgc-ci-18px sgc-ci-hover-off mb-0" name="AgreementCheck" id="AgreementCheck" onChange={handleOnChange} 
                    label={
                      <>
                        I certify that I am 18 years of age or older, agree to the{' '}
                        <Link to="/user-agreement">User Agreement</Link>, and acknowledge the{' '}
                        <Link to="/privacy-policy">Privacy Policy</Link>.
                      </>
                    }/>
                  </div>
                </div>

                <div className="col-12">
                  <div onClick={() => handleStepClick('step2')} className="common-submit-btn">Continue</div>
                  <div className="lsb-loader-btn"><Icons.LoadingSvg/> Please wait ....</div>
                </div>

                <div class="col-12">
                  <div class="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Already have an account?
                    <Link class="signinup-link-two" to="/signin">Sign in</Link></div>
                </div>

              </div>
            </form>
        </div>

        <div className={`signinup-screen ${step === 'step2' ? '' : 'd-none'} `} id="step2">

            <Logo className="signinup-logo-bx" />

            <div className="signinup-header">
              <div className="screen-hed">Set a password</div>
              {/* <div className="screen-pera">to access your account</div> */}
            </div>

            <form className="signinup-width">
              <div className="row">

                
                <div className="col-12">
                   <PasswordInput heading="Password" type="password" placeholder="Enter password" onChange={handleOnChange} errorMeg="Password is required" />
                </div>

                <div className="col-12">
                   <PasswordInput heading="Confirm password" type="password" placeholder="Enter confirm password" onChange={handleOnChange} errorMeg="Confirm password is required" />
                </div>

                <div className="col-12">
                  <div className="common-submit-btn">Submit</div>
                  <div className="lsb-loader-btn"><Icons.LoadingSvg/> Please wait ....</div>
                </div>

                <div class="col-12">
                  <div class="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                        Back to
                        <div onClick={() => handleStepClick('step1')} className="signinup-link-two">Previous</div>
                    </div>
                </div>

              </div>
            </form>
        </div>

    </section>
  )
}